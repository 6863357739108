import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import React, { useState } from 'react';
import { ShiftResource, ShiftTeamPlan } from 'app/shared/model/custom/custom-shift-resource.model';
import dayjs from 'dayjs';
import { getCalendarCellBgColorById } from 'app/shared/util/color-utils';
import { convertDateTimeFromServer, isSameDay } from 'app/shared/util/date-utils';

interface CalendarItemProps {
  date: dayjs.Dayjs;
  isInVacation?: boolean;
}

const CalendarItem = ({ date }: CalendarItemProps) => {
  const resourceShifts = useAppSelector(state => state.calendar.resourceShifts);
  const shiftsWithResourcePlan = resourceShifts?.shiftsWithResourcePlan || [];
  const shiftsWithTeamPlan = resourceShifts?.shiftsWithTeamPlan || [];

  const resourceShift: ShiftResource | undefined = Array.isArray(shiftsWithResourcePlan)
    ? shiftsWithResourcePlan.find(
        shift => isSameDay(date.toDate(), new Date(convertDateTimeFromServer(shift.startTime))) && shift.status === 'PUBLISHED',
      )
    : undefined;
  const teamShift: ShiftTeamPlan | undefined = Array.isArray(shiftsWithTeamPlan)
    ? shiftsWithTeamPlan.find(shift => isSameDay(date.toDate(), new Date(convertDateTimeFromServer(shift.startTime))))
    : undefined;
  const [isOpen, setIsOpen] = useState(date.isSame(dayjs(), 'day') && resourceShift ? true : false);

  const handleCollapse = () => {
    if (resourceShift?.equipment && resourceShift?.organigram) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <button onClick={handleCollapse} className="bg-navyBlue flex items-center justify-between px-4 py-2 text-neutral-50">
        <div>
          <FontAwesomeIcon
            className={`mr-2 transform transition-transform duration-200 ${isOpen ? 'rotate-90' : 'rotate-0'}`}
            icon={faCaretRight}
          />
          <span className="font-title">{date.format('dddd DD MMM YYYY')}</span>
        </div>
        <span
          className={`border-l-4 min-w-[100px] min-h-full text-neutral-900 bg-opacity-80 text-sm py-1 px-2`}
          style={{
            borderLeftColor: getCalendarCellBgColorById(teamShift?.refShiftType?.id, true),
            backgroundColor: resourceShift && getCalendarCellBgColorById(resourceShift?.refShiftType?.id, true),
          }}
        >
          {resourceShift?.refShiftType?.name || teamShift?.refShiftType?.name}
        </span>
      </button>
      <div
        className={`bg-background flex justify-between px-4 border-l-4 border-navyBlue overflow-hidden transition-max-height duration-500 ease-in-out ${
          isOpen ? 'max-h-40 py-2' : 'max-h-0 py-0'
        }`}
      >
        <div
          className={`px-4 w-full transform flex justify-between transition-transform duration-500 ${
            isOpen ? 'translate-y-0 opacity-100' : '-translate-y-2 opacity-0'
          }`}
        >
          <div>
            {resourceShift && resourceShift?.organigram?.refDepartment?.name
              ? `${resourceShift?.organigram?.refDepartment.name} / ${
                  resourceShift?.organigram?.refPosition && resourceShift?.organigram?.refPosition.name
                }`
              : ''}
          </div>
          <div>{resourceShift && resourceShift?.equipment?.name}</div>
        </div>
      </div>
    </div>
  );
};

export default CalendarItem;
