import React, { useMemo } from 'react';
import { useLocation, Navigate, PathRouteProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';

import { useAppSelector } from 'app/config/store';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { hasAnyPermission } from '../reducers/authentication.selector';

interface IOwnProps extends PathRouteProps {
  hasAnyAuthorities?: string[];
  hasAnyPermissions?: string[];
  children: React.ReactNode;
}

export const PrivateRoute = ({ children, hasAnyAuthorities = [], hasAnyPermissions = [], ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const account = useAppSelector(state => state.authentication.account);
  const permissions: string[] = useAppSelector(state => state.authentication.permissions);
  const isAuthorized = useMemo(() => hasAnyAuthority(account.authorities, hasAnyAuthorities), [account.authorities, hasAnyAuthorities]);
  const isPermissed = useMemo(() => hasAnyPermission(permissions, hasAnyPermissions), [permissions, hasAnyPermissions]);

  const pageLocation = useLocation();

  if (!children) {
    throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
  }

  if (!sessionHasBeenFetched) {
    return <div></div>;
  }

  if (isAuthenticated) {
    if (permissions.includes('view_mobile') && pageLocation.pathname !== '/user-calendar') {
      return <Navigate to="/user-calendar" replace />;
    }
    if (pageLocation.pathname === '/') {
      return <Navigate to="/planning" replace />;
    }

    if (isAuthorized && isPermissed) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to={{
        pathname: '/login',
        search: pageLocation.search,
      }}
      replace
      state={{ from: pageLocation }}
    />
  );
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

/**
 * Checks authentication before showing the children and redirects to the
 * login page if the user is not authenticated.
 * If hasAnyAuthorities is provided the authorization status is also
 * checked and an error message is shown if the user is not authorized.
 */
export default PrivateRoute;
